<template>

  <div class="flex w-full flex-col lg:flex-row items-center mb-4">

    <!-- por nombre -->
    <div class="w-full lg:w-1/3">
      <b-field :label="$t('search_by_name')">
        <b-input
          v-model="filters.text"
          :placeholder="$t('name')"
          type="search"
          icon="search"
          icon-pack="fas"
          ref="inputName"
          @input="checkInputTextFilter(filterName)">
        </b-input>
      </b-field>
    </div>

    <!-- por flujo -->
    <div class="w-full lg:w-1/3 ml-4">
      <b-field :label="$t('search_by_validation_flow')">
        <b-select ref="input"
                  v-model="filters.template_id"
                  :placeholder="$t('select')"
                  icon="search"
                  icon-pack="fas"
                  @input="loadDocumentsList()"
                  expanded
        >
          <option :value="null">{{$t('select')}}</option>
          <option v-for="flowOption in templatesList" :value="flowOption.id" :key="'flow_'+flowOption.id">
                    {{ flowOption.name }}
                  </option>
        </b-select>
      </b-field>
    </div>

    <!-- por estado -->
    <div class="w-full lg:w-1/3 ml-4">
      <b-field :label="$t('search_by_status')">
        <b-select ref="input"
                  v-model="filters.status"
                  :placeholder="$t('status')"
                  icon="search"
                  icon-pack="fas"
                  @input="loadDocumentsList()"
                  expanded
        >
          <option :value="null">{{$t('validation_status_all')}}</option>
          <option value="99">{{$t('uncompleted')}}</option>
          <option value="0">{{$t('validation_status_pending')}}</option>
          <option value="1">{{$t('validation_status_pending_validation')}}</option>
          <option value="4">{{$t('validation_status_pending_answer')}}</option>
          <option value="2">{{$t('validation_status_completed')}}</option>
          <option value="3">{{$t('validation_status_rejected')}}</option>
          <option value="5">{{$t('validation_status_reopened')}}</option>
        </b-select>
      </b-field>
    </div>

  </div>

</template>

<script>
  export default {

    components: {

    },

    props: {
      templatesList: { type: Array, required: true },
      filters: { type: Object, required: true }
    },

    data: () => ({
      filterName: '',
      filterTemplate: null,
      filterStatus: null,
    }),

    methods: {

      generateFiltersOLD() {

        let params = {};

        // Filtro por nombre
        if (this.filterName.length >= 3) {
          params.text = this.filterName
        }
        //Filtro por creador
        if (this.filterTemplate !== null) {
          params.template_id = this.filterTemplate
        }

        if (this.filterStatus !== null) {
          params.status = this.filterStatus
        }

        return params
      },

      generateFilters() {
        return { ...this.filters }; // Retorna los valores actuales
      },

      applyFilters() {
        let args = this.generateFilters();
        // this.$emit('foldersfilters-updated', args);
      },

      // cleanFiltersClicked() {
      //   this.filters = {
      //       type: null,
      //       status: null,
      //       discipline: null,
      //       name: null,
      //       tag: null,
      //       created: null,
      //   }
      //   this.applyFilters()
      // },

      cleanFiltersClicked() {
        this.filterName = '';
        this.filterTemplate = null;
        this.filterStatus = null;
        this.documents = []
        this.total = -1
        this.cleanFilters()
      },

      cleanFilters() {
        this.filters.text = '';
        this.filters.template_id = null;
        this.filters.status = null;
      },

      checkInputTextFilter(filter) {
        if (filter.length >= 3 || filter.length == 0) {
          this.applyFilters()
        }
      },

      loadDocumentsList() {
      },
    }
  };
</script>

<style scoped>
</style>
