<template>
  <b-modal :active.sync="isModalActive" @after-leave="hide()" scroll="keep" :can-cancel="canCancel">
    <div class="bg-white border rounded shadow-lg border-blue-light">

      <header class="modal-card-head">
        <p class="modal-card-title">Configurar carpetas de actividades</p>
      </header>

      <section class="modal-card-body">
        <b-collapse
          class="card"
          animation="slide"
          v-if="!isLoading"
          v-for="(item, index) of data"
          :key="index"
          :open="isOpen == index"
          @open="isOpen = index"
          :aria-id="'contentIdForA11y5-' + index">
          <template #trigger="props">
              <div
                class="card-header"
                role="button"
                :aria-controls="'contentIdForA11y5-' + index"
                :aria-expanded="props.open"
                :style="item.value ? 'background: #cec': 'background: #ecc'"
              >
                  <p class="card-header-title">
                      {{ item.label }}
                  </p>
                  <a class="card-header-icon">
                      <b-icon pack="fa" :icon="props.open ? 'caret-down' : 'caret-up'">
                      </b-icon>
                  </a>
              </div>
          </template>
          <div class="card-content">
              <div class="content">
                <div class="flex flex-col">
                  <b-field label="Selecciona carpeta"></b-field>
                    <treeselect 
                        v-model="item.value" 
                        :close-on-select="true" 
                        :required="false"
                        :options="item.folders" 
                    />
                </div>
              </div>
          </div>
        </b-collapse>

        <scale-loader v-if="isLoading"/>
      </section>
      
      <div class="px-4 pb-4">
        <button class="button is-success" v-on:click="hide()">{{ $t('save') }}</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Vue from 'vue';
import axios from "axios";
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  components: {
    ScaleLoader,
    Treeselect
  },

  data: () => ({
    isModalActive: false,
    canCancel: ['escape', 'x', 'outside'],
    isOpen: null,
    isLoading: false, 
    data: []
  }),

  methods: {
    async show(data) {
      this.isModalActive = true;
      this.isLoading = true;
      this.isOpen = null;
      this.data = data
      this.isLoading = false;
    },

    hide() {
      this.$emit('close-modal', this.data)
      this.canCancel = ['escape', 'x', 'outside']
      this.isModalActive = false;
    }
  },
};
</script>
