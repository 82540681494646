<template>
    <div>
        <div class="top-bar flex justify-end">
            <!-- <v-button @click="() => { showFormImportEntities = true }" icon="file-import" :disabled="showFormData">{{
                $t('indirect_costs') }}</v-button> -->

            <v-button icon="plus" @click="goToPage('add-indirect-cost')" class="ml-4" :disabled="showFormImportEntities">
                {{ $t('add_indirect_cost_chapter') }}
            </v-button>
        </div>
        <!-- Table Indirect Cost -->
        <indirect-costs :projectId="projectId" ref="indirect_costs_table" @epc-edit="handleEditIndirectCost" />
        <indirect-costs-modal ref="indirect_cost_modal" :projectId="projectId" @reload-list="handleReload"></indirect-costs-modal>
    </div>
</template>

<script>

import IndirectCosts from "./indirect-costs.vue";
import IndirectCostsModal from "./indirect-cost-modal.vue";
export default {
    components: {
        IndirectCosts,
        IndirectCostsModal
    },
    props: {
        projectId: {
            type: Number,
            require: true
        },
    },
    data() {
        return {
            showFormImportEntities: false,
            showFormData: false,
        }
    },
    methods: {
        handleReload() {
            this.$refs.indirect_costs_table.reload();
        },
        openIndirectCost(indirectCost) {
            this.$refs.indirect_cost_modal.show(indirectCost);
        },
        handleEditIndirectCost(indirect_cost) {
            this.openIndirectCost(indirect_cost);
        },
        goToPage(destiny) {
            switch (destiny) {
                case 'add-indirect-cost':
                    this.$router.push({
                        name: 'project.admin.cost-management.indirect-cost',
                        params: {
                            projectId: this.projectId,
                        }
                    });
                    break;
            }
        },
    },
}
</script>

<style lang="scss" scoped></style>