<template>
    <b-modal :active.sync="isModalActive" :width="1028" scroll="keep" :can-cancel="canCancel" @close="closeModal">
        <div class="border border-blue-light rounded shadow bg-white p-4">
            <form @submit.prevent="saveDetail" class="w-full">
                <header class="font-thin uppercase text-blue-light modal-card-head_ mb-4">
                    {{ $t('add_indirect_cost_detail') }}
                </header>
                <div class="modal-card-body_ relative">
                    <!-- Flexbox layout for the form fields -->
                    <div class="flex flex-wrap mx-4 ">
                        <!-- First Row -->
                        <div class="w-1/2 px-4">
                            <b-field :label="$t('code')">
                                <b-input v-model="detail.code" title="code" required name="code"
                                    :placeholder="$t('code')" maxlength="100" />
                            </b-field>
                        </div>
                        <div class="w-1/2 px-4">
                            <b-field :label="$t('description')">
                                <b-input v-model="detail.description" title="description" required name="description"
                                    :placeholder="$t('description')" maxlength="255" />
                            </b-field>
                        </div>
                    </div>
                    <div class="flex flex-wrap mx-4 mb-3">
                        <!-- Second Row -->
                        <div class="w-1/2 px-4 mb-3">
                            <b-field :label="$t('price')">
                                <b-input v-model.number="detail.price" title="price" required name="price"
                                    :placeholder="$t('price')" maxlength="100" type="number" step="0.01" />
                            </b-field>
                        </div>
                        <div class="w-1/2 px-4 mb-3">
                            <b-field :label="$t('quantity')">
                                <b-input v-model.number="detail.quantity" title="quantity" required name="quantity"
                                    :placeholder="$t('quantity')" maxlength="100" type="number" step="0.01" />
                            </b-field>
                        </div>
                    </div>
                    <div class="flex flex-wrap mx-4 mb-3 justify-end">
                        <v-button type="submit" class="px-4">{{ $t('save') }}</v-button>
                    </div>
                </div>
            </form>
        </div>
    </b-modal>
</template>

<script>
import axios from 'axios';

export default {
    props: {
        projectId: {
            type: Number,
            required: true
        },
    },
    data() {
        return {
            isModalActive: false,
            canCancel: ['escape', 'x', 'outside'],
            detail: {
                code: '',
                description: '',
                price: 0,
                quantity: 0
            },
            indirectCostCode: '',
            name: '',
            details: null
        };
    },
    methods: {
        show({ code, name, detail }) {
            this.indirectCostCode = code;
            this.name = name;
            this.detail = detail;
            this.isModalActive = true;
        },
        closeModal() {
            this.isModalActive = false;
        },
        async saveDetail() {
            try {
                const response = await axios.post('/api/v2/indirect-cost', {
                    ...this.detail,
                    indirect_cost_code: this.indirectCostCode,
                    name: this.name,
                    project_id: this.projectId
                });

                if (response.data.success) {
                    this.$toast.open({
                        message: this.$t('save_successful'),
                        type: 'is-success',
                        position: 'is-top-right'
                    });
                    this.$emit('reload-list', response.data.indirect_cost );
                    this.closeModal();
                }
            } catch (error) {
                const errorMessage = error.data && error.data.message
                    ? error.data.message
                    : this.$t('save_error');

                this.$toast.open({
                    message: errorMessage,
                    type: 'is-danger',
                    position: 'is-top-right'
                });
                console.error('Error saving detail:', error);
            }
        }
    }
}
</script>

<style scoped></style>
