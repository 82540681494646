<template>
    <b-modal :active.sync="isModalActive" :width="1028" scroll="keep" :can-cancel="canCancel" @close="closeModal">
        <div class="border border-blue-light rounded shadow bg-white p-4">
            <form @submit.prevent="saveChanges" class="w-full">
                <header class="font-thin uppercase text-blue-light modal-card-head_ mb-4">Añadir / editar importacion
                </header>
                <div class="modal-card-body_ relative">
                    <div class="border-grey-light border-b border-dashed mb-3">
                        <div class="w-full flex flex-wrap">
                            <div v-for="(value, key) in formData" v-if="!['skip', 'import_status', 'actions', 'status_error'].includes(key)" class="w-1/3 px-4 mb-3">
                                <div class="field">
                                    <label class="label">{{ $t(key) }}</label>
                                    <div class="control">
                                        <input class="input" type="text" v-model="formData[key]">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <b-loading :is-full-page="false" v-model="processingData" :can-cancel="false"></b-loading>
                    </div>
                </div>
                <footer class="modal-card-foot_">
                    <div class="w-full inline-flex justify-end">
                        <button class="btn btn-blue" nativeType="submit" v-if="!processingData">{{
                            $t('save')
                            }}</button>
                        <v-button-processing v-if="processingData" />
                    </div>
                </footer>
            </form>
        </div>
    </b-modal>
</template>

<script>
export default {
    props: {
        rowData: {
            type: [],
        }
    },
    data() {
        return {
            isModalActive: false,
            canCancel: ['escape', 'x', 'outside'],
            processingData: false,
            formData: {},
        }
    },
    methods: {
        show(rowData) {
            this.formData = { ...rowData };
            this.isModalActive = true;
        },
        closeModal() {
            this.isModalActive = false;
            this.$emit('update:show', !this.show)
        },
        saveChanges() {
            this.$emit('saved', this.formData);
            this.closeModal();
        }

    },
}
</script>

<style lang="scss" scoped></style>