<template>
<div class="panel">
  <div class="w-full flex items-center justify-between mb-8">
    <div class=" uppercase text-red-light font-thin text-lg">
      {{ $t('issues') }}
    </div>
    <v-button-icon-round
      icon="plus"
      tooltip="add_issue"
      tooltip-position="left"
      color="red"
      @click="addIssueButtonClicked"
      v-if="canAdd"
    />
  </div>

  <div v-if="isLoading" class="text-center my-4">
    <scale-loader></scale-loader>
  </div>
  <div v-else>
    <!-- filtros  -->
    <div class="flex items-center w-full  mb-4">
      <!-- type -->
          <b-select
            class="mr-2"
            size="is-small"
            rounded
            :placeholder="$t('type')"
            v-model="typeFilter"
            @input="getIssues()"
          >
            <option value="all">{{ $t('all') }}</option>
            <option v-for="option in issueTypesList" :value="option.id" :key="option.value">{{ option.name }}</option>
          </b-select>
        
        <!-- class -->
          <b-select 
            class="mr-2"
            size="is-small"
            rounded 
            :placeholder="$t('classification')"
            v-model="classFilter"
            @input="getIssues()"
          >
            <option value="all">{{ $t('all') }}</option>
            <option v-for="option in issueClassesList" :value="option.id" :key="option.value">{{ option.name }}</option>
          </b-select>
        
    </div>
    <!-- Incidencias -->
    <b-collapse v-if="list.length > 0" :open.sync="isListOpen">
      <div slot="trigger" slot-scope="props"
        class="flex items-center font-semibold text-sm"
      >
        {{ list.length + ' ' + $t('issues') }}
        <b-icon pack="fas" :icon="props.open ? 'caret-up' : 'caret-down'" class="text-blue ml-2" />
      </div>
      <!-- Incidencia -->
      <div v-for="issue in list" 
           :class="(issue.can_access ? 'bg-grey-lighter cursor-pointer' : 'no_access') + ' my-1 p-2'"
           @click="issue.can_access ? onClickIssue(issue.id) : () => false"
           :key="issue.id"
      >
        <!-- Top -->
        <div class="flex items-center justify-start mb-4">
          <!-- Usuario -->
          <div class="flex w-full items-center float-left">
            <div class="rounded-full overflow-hidden border border-grey w-10 h-10 mr-2">
              <img :src="issue.manager.avatar" :alt="issue.manager.fullname">
            </div>
            <div>
              <div :class="(issue.can_access ? 'text-blue' : '') + ' font-medium flex items-center'">
                {{ issue.name }}
              </div>
              <div :class="(issue.can_access ? 'text-blue-light' : '') + ' text-sm'">
                {{ issue.manager.fullname }}
              </div>
            </div>
          </div>

          <!-- Status -->
          <div class="flex float-right mt-2">
            <entity-notifications
                    :entityId="issue.id"
                    entityType="issue"
                    :mentions="issue.notifications.mentions"
                    :comments="issue.notifications.comments"
                    :documents="issue.notifications.documents + issue.notifications.media"
                    :enabledChangedStatus="false"
            />
          </div>

          <!-- Status -->
          <div class="flex float-right mt-2">
            <span class="rounded-sm py-1 px-2 text-xs text-white font-semibold uppercase" :class="{ 'bg-red': !issue.is_open, 'bg-green-dark': issue.is_open}">
              {{ $t(issue.is_open ? 'opened' : 'closed')  }}
            </span>
          </div>
        </div>

        <!-- Descripción -->
        <div v-html="issue.description" :class="(issue.can_access ? 'text-grey-darker' : 'no_access') + ' flex text-sm w-full mb-2 line-clamp-2'"></div>
        <!-- Fechas -->
        <!-- <div class="flex text-sm">
          <span class="mr-4">{{ $t('issue_date') }}: <strong :class="issue.can_access ? '' : 'no_access'">{{ issue.created_at }}</strong></span>
          <span v-if="! issue.is_open">{{ $t('closed_date') }}: <strong :class="issue.can_access ? '' : 'no_access'">{{ issue.closed_at }}</strong></span>
        </div> -->
      </div>
    </b-collapse>
    <div v-else>
      {{ $t('no_results') }}
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios'
import entityNotifications from '·/components/entity-notifications'
import EventBus from '~/plugins/bus'
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'activity-issues',

  components: {
    entityNotifications,
    ScaleLoader
  },
  props: {
    activityId: { type: Number, required: true },
    activityName: { type: String, required: true },
    subactivityId: { type: Number, default: 0 },
    canAdd: { type: Boolean, default: false },
  },

  data: () => ({
    list: [],
    isListOpen: true,
    routeName: '',
    isLoading: true,
    classFilter: null,
    typeFilter: null,
    issueClassesList: [],
    issueTypesList: [],
  }),

  watch: {
    activityId(id) {
      if (id > 0) {
        this.isLoading = true
        this.getIssues()
      }
    }
  },

  computed: {
    ...mapGetters({
      project: 'app/project',      
    }),
  },

  mounted () {
    this.routeName = this.subactivityId ? 'subissue' : 'issue'
  },

  created() {
    let self = this;
    this.getIssues();
    this.getClassTypes();

    // Si recibimos el evento refresh, refrescamos el componente
    EventBus.$on('refreshIssuesNotifications', function(e){
      self.isLoading = true
      self.getIssues();
    });
  },

  beforeDestroy(){
  	EventBus.$off("refreshIssuesNotifications")
  },

  methods: {
    async getIssues() {
      const params = {
        activity_id: this.activityId,
        class_id: this.classFilter === 'all' ? null : this.classFilter,
        type_id: this.typeFilter === 'all' ? null : this.typeFilter,
      }
      let url = '/api/v2/issues'
      const { data } = await axios.get(url, {params: params})

      if (data && data.issues) {
        this.list = data.issues
        this.isLoading = false;
      }
    },

    async getClassTypes() {
      const { data } = await axios.get('/api/v2/project/' + this.project.id + '/issue-classes-types-disciplines-origins')
      if (data && data.success) {
        this.issueClassesList = data.classes
        this.issueTypesList = data.types
      } else {
        this.$notify.error('Error cargando tipos / disciplinas / origenes')
      }
    },
    addIssueButtonClicked() {
      this.$router.push({
        name: this.routeName + '.create',
        params: {
          activityName: this.activityName
        }
      })
    },
    onClickIssue(id) {

      if(this.subactivityId){

          // Nos lleva hacia la incidencia
          this.$router.push({ name: 'issue.home-sub', params: { issue_id: id, subactivity_id: this.subactivityId, id: this.activityId } })

      }else{

          // Nos lleva hacia la incidencia
          this.$router.push({ name: 'issue.home', params: { issue_id: id, id: this.activityId } })
      }

    },
  },

};
</script>

<style scoped>
  .no_access {
    color: #B8C2CB;
    background-color: #F8FAFC;
  }

  .line-clamp-2 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
</style>
