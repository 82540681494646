<template>
  <div class="inset-0 w-full h-screen bg-blue-darkest items-center flex text-grey-light">
    <div class="max-w-sm w-full mx-auto">
      <div class="logo w-1/2 mx-auto h-12"></div>
      <div class="p-8 mb-6">
        <h1 v-if="environment == 'staging'" class="text-2xl text-center text-red font-bold">** Entorno de pruebas **</h1>
        <h1 class="text-2xl font-medium text-grey-light text-center mb-4">{{ pageInfo.title }}</h1>
        <child/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'LoginLayout',

  data: () => ({
    appName: window.config.appName,
    environment: window.config.env,
  }),

  computed: mapGetters({
    pageInfo: 'app/pageInfo'
  }),

};
</script>
