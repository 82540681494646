<template>
    <div>
        <div class="top-bar flex justify-end">
            <v-button @click="() => { showFormImportEntities = true }" icon="file-import" :disabled="showFormData">{{
                $t('import_cost_budget') }}</v-button>
            <!-- <v-button @click="openAssignmentBreakdownModal(null)" icon="plus" class="ml-4"
                :disabled="showFormImportEntities">{{
                    $t('add_cost_budget') }}</v-button> NO NECESARIO EN ESTA PESTAÑA -->
            <div class="export-button-container ml-4">
                <v-button @click="exportData" color="green" icon="file-export">
                    <span v-if="!isLoading">{{ $t('export') }}</span>
                    <span v-else>
                        <i class="fa fa-spinner fa-spin"></i> {{ $t('loading') }}...
                    </span>
                </v-button>
            </div>
        </div>
        <!-- <div>
            <cost-management-filters ref="filtersInventoryRef" :projectId="projectId" :providers="providers"
                @filters-updated="filtersUpdate" />
        </div> -->
        <div class="flex">
            <div :class="[(showFormData || showFormImportEntities ? 'w-7/12' : 'w-full'), 'mr-2']">
                <assignment-breakdown-table ref="budgets_table" :projectId="projectId" @epc-edit="handleEditEntity"
                    @add-by-assignment="handleAddByAssignment" @reload-list="handleReload" />
            </div>
            <transition name="fade">
                <div class="w-5/12 ml-2" v-if="showFormImportEntities">
                    <cost-management-import :projectId="projectId" :entity-type="'cost-management'"
                        @epc-close="(event) => { showFormImportEntities = false }"
                        @epc-success-import="handleReloadImport" />
                </div>
            </transition>
        </div>
        <assignment-breakdown-modal ref="assignment_breakdown_modal" :projectId="projectId"
            @reload-list="handleReload"></assignment-breakdown-modal>

        <cost-management-modal ref="cost_management_modal" :projectId="projectId"
            @reload-list="handleReload"></cost-management-modal>
    </div>
</template>

<script>

import axios from 'axios';
// import CostManagementFilters from '../cost-management-filters'
import CostManagementModal from '../cost-budget-modal.vue'
import CostManagementImport from '../cost-management-import.vue';
import AssignmentBreakdownTable from './assignment-breakdown-table.vue';
import AssignmentBreakdownModal from './assignment-breakdown-modal.vue';
// import CostBudgets from '../cost-budgets.vue';
export default {
    name: "manage-assignment-breakdown",
    components: {
        // CostBudgets,
        // CostManagementFilters,
        CostManagementModal,
        CostManagementImport,
        AssignmentBreakdownTable,
        AssignmentBreakdownModal
    },
    props: {
        projectId: { type: Number, required: true },
        company: { type: Number, required: true },
    },
    data: () => ({
        material: null,
        showFormData: false,
        showFormImportEntities: false,
        showFormLink: false,
        providers: [],
        units: [],
        assignments: [],
        isLoading: false,
        costBudgets: [],
    }),
    created() {
        this.getProviders();
        this.getAssignment();
        this.getCostbudget();
        this.getUnits();
    },
    methods: {
        handleReloadImport() {
            this.showFormImportEntities = false;
            this.handleReload();
        },
        handleReload() {
            this.$refs.budgets_table.reload();
            this.getCostbudget();
            this.$emit("reload-list");
        },
        async getProviders() {
            try {
                const { data } = await axios.get(`/api/v2/project/${this.projectId}/providers`);
                if (data && data.success) {
                    this.providers = data.providers;
                }
            } catch (error) {
                throw ('Error fetching providers:', error);
            }
        },
        async getUnits() {
            try {
                const { data } = await axios.get(`/api/v2/company/${this.company}/measurement-units`);
                if (data && data.success) {
                    this.units = data.units;
                }
            } catch (error) {
                throw ('Error fetching units:', error);
            }
        },

        async getAssignment() {
            try {
                const { data } = await axios.get(`/api/v2/project/${this.projectId}/assignments-raw`);
                if (data && data.success) {
                    this.assignments = data.assignments;
                }
            } catch (error) {
                throw ('Error fetching assignment:', error);
            }
        },
        async getCostbudget() {
            try {
                const { data } = await axios.get(`/api/v2/cost-budgets`, {
                    params: {
                        project_id: this.projectId
                    }
                });
                if (data && data.success) {
                    this.costBudgets = data.budgets;
                }
            } catch (error) {
                throw ('Error fetching budgets:', error);
            }
        },

        handleSuccess() {
            this.$refs.inventories_list.reload()
        },
        handleEditEntity(entity) {
            this.openCostManagementModal(entity)
        },
        handleAddByAssignment(assignment) {
            this.openAssignmentBreakdownModal(null, assignment)
        },
        // Botones de acción
        openCostManagementModal(budget = null) {
            this.$refs.cost_management_modal.show(this.providers, this.assignments, budget);
        },

        // Botones de acción
        openAssignmentBreakdownModal(budget = null, assignment = null) {

            this.$refs.assignment_breakdown_modal.show(this.providers, this.units, this.assignments, this.costBudgets, budget, assignment);
        },
        filtersUpdate(args = {}) {
            this.$refs.budgets_table.reload(args);
        },
        async exportData() {
            this.isLoading = true;
            try {
                const response = await axios.post('/api/v2/export/cost-budget', {
                    project_id: this.projectId,
                    orderDir: 'asc',
                    orderField: 'id',
                    filters: {}
                }, {
                    responseType: 'blob'
                });

                // Crear un enlace para descargar el archivo
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'cost_budget.xlsx');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } catch (error) {
                throw ("Error al exportar datos:", error);
            }
            finally {
                this.isLoading = false;
            }
        }
    }
}
</script>

<style lang="scss" scoped></style>