<template>
  <div class="navbarmenu-notifications">
    <b-dropdown>
      <a v-if="isPublic" slot="trigger" class="text-black hover:text-grey">
        <b-icon icon="bell" pack="fas" size="is-small" class="float-left"></b-icon>
        <span class="px-1 py-0 text-xs text-white rounded-full bg-red">{{ total }}</span>
      </a>
      <a v-else slot="trigger" class="text-black hover:text-grey">
        <b-icon icon="bell" pack="fas" size="is-small" class="float-left"></b-icon>
      </a>

      <div class="w-full pb-2 pr-2 text-right" v-if="total > 0">
        <a
          v-on:click="getNotifications(false, 1)"
          v-if="onlyUnread"
          class="button is-light is-small"
        >
          <b-icon pack="fa" class="float-left" size="is-small" icon="eye"></b-icon>
          <span>{{ $t('notifications_show_all') }}</span>
        </a>

        <a v-on:click="showUnread(1)" v-if="!onlyUnread" class="button is-light is-small">
          <b-icon pack="fa" class="float-left" size="is-small" icon="envelope"></b-icon>
          <span>{{ $t('notifications_show_unread') }}</span>
        </a>

        <a v-on:click="readAllNotifications" class="ml-1 button is-light is-small">
          <b-icon pack="fa" class="float-left" size="is-small" icon="check-circle"></b-icon>
          <span>{{ $t('notifications_mark_as_read') }}</span>
        </a>
      </div>
      <div class="w-full pb-2 pr-2 text-sm font-bold text-center" v-else>
        <span>{{ $t('notifications_not_pending_to_read') }}</span>
      </div>

      <b-dropdown-item
        v-for="notification in notifications"
        :key="notification.id"
        style="padding-right:1rem!important; border-top:1px solid #e9e9e9; width: 500px; white-space: initial"
        v-bind:class="{ 'bg-grey-lighter': !notification.read_at }"
      >
        <div
          :id="notification.id"
          v-on:click="goToNotification(notification.id)"
          class="media"
        >
          <div class="mt-1 media-left relative">
            <img class="rounded-full" width="60" height="60" :src="notification.data.avatar">
            <b-icon
                pack="fa"
                class="rounded-full absolute bottom-0 -right-1.5 text-white w-6 h-6"
                style="background-color: rgb(85, 119, 187)"
                :icon="notification.data.notification_icon"
              ></b-icon>
          </div>

            <!-- style="overflow:visible!important; min-width:500px;" -->
          <div
            class="media-content"
          >
            <h3 class="font-semibold">{{ notification.data.notification_title }}</h3>
            <div v-if="notification.data.flow_activity_name">{{ notification.data.flow_activity_name }}</div>
            <div v-if="notification.data.flow_parent_activity_name">{{ notification.data.flow_parent_activity_name }}</div>
            <!-- <b-icon
              pack="fa"
              class="float-left mt-1 mr-1"
              size="is-small"
              :icon="notification.data.notification_icon"
            ></b-icon> -->
            <div class="">
              <p class="text-sm text-gray-600 line-clamp-3">{{ notification.data.notification_message }}</p>
            </div>

            <div v-if="notification.data.notification_title != projectName(notification)">
              <span class="text-xs italic"> ({{ projectName(notification) }})</span>
            </div>

            <div>
              <l-timeago
                :since="notification.created_at"
                :locale="locale"
                class="my-1 text-xs font-semibold text-blue-light"
              />
            </div>
          </div>
        </div>
      </b-dropdown-item>

      <div class="w-full pb-2 pr-2 text-center">
        <a v-on:click="fetchData" class="button is-light is-small" v-if="this.page < this.last">
          <b-icon pack="fa" class="float-left" size="is-small" icon="eye"></b-icon>
          <span>Ver más</span>
        </a>
      </div>
    </b-dropdown>
  </div>
</template>

<script>
import Vue from "vue";
import Pusher from "pusher-js";
import axios from "axios";
import store from "~/store";
import Echo from "laravel-echo";
import EventBus from "~/plugins/bus";
import { mapGetters } from "vuex";
import VueLodash from "vue-lodash";
import MugenScroll from "vue-mugen-scroll";
import FirebasePlugin from "~/plugins/firebase";

const options = { name: "lodash" };
Vue.use(VueLodash, options);

export default {
  components: {
    MugenScroll
  },

  data() {
    return {
      isPublic: true,
      loading: false,
      isLoadingTable: true,
      page: 1,
      last: 1,
      total: 0,
      onlyUnread: false,
      notifications: [],
      params: []
    };
  },
  computed: {
    ...mapGetters({
      locale: "lang/locale",
      company: 'app/company',
      companies: 'app/companies',
      project: 'app/project',
      importEntities: 'app/importEntities'
    })
  },
  methods: {

    projectName(notification) {
      return notification.data.activity_project_name || notification.data.flow_project_name || ''
    },

    playSound(sound) {
      if (sound) {
        var audio = new Audio(sound);
        audio.play();
      }
    },

    fetchData() {
      if (this.page <= this.last) {
        this.isLoadingTable = true;
        this.getNotifications(false, this.page);
        this.isLoadingTable = false;
      }
    },

    async showUnread(page) {
      // Marcamos
      var self = this;
      this.onlyUnread = true;
      this.notifications = [];

      // Obtenemos las notificaciones sin leer del usuario
      const { data } = await axios.get(
        "/api/v2/user/notifications/unread/web?page=" + page
      );
      //console.log(data);
      // Paginas totales
      this.last = data.last;

      if (data && data.success) {
        // Hacemos comprobaciones de la paginacion
        if (this.page > 1 && this.page <= this.last) {
          data.data.forEach(function(element) {
            self.notifications.push(element);
          });

          this.isLoadingTable = false;
          this.page = this.page + 1;
        } else {
          self.notifications = data.data;
          this.isLoadingTable = false;
          this.page = this.page + 1;
        }

        // Variables para mostrar total de notificaciones independiente de la paginacion
        this.total = data.unread;
        this.total > 0 ? (this.isPublic = true) : (this.isPublic = false);
      }
    },

    async readAllNotifications() {
      // Obtenemos las notificaciones sin leer del usuario
      const { data } = await axios.get("/api/v2/user/notifications/readall");

      if (data && data.success) {
        // Obtenemos las notificaciones de nuevo.
        this.getNotifications(false, this.page);

        // Notificamos con buefy
        this.$notify.info("all_read");

        // Refrescamos los componentes dependiente de notificaciones
        EventBus.$emit("refreshNotifications", true);
      }
    },

    async connectToFirebase() {

      if (! FirebasePlugin.isSupported())
        return 0;

      FirebasePlugin.initialize();

      // Gestiona recepcion de mensajes en primer plano (pestaña activa)
      // (gestion de mensajes en 2º plano en public/firebase-messaging-sw.js)
      FirebasePlugin.messaging.onMessage(function(payload) {

        // notificaciones silenciosas no traen la key 'notification'
        if (!payload.notification) {
          console.log("no payload.notification detected")
          return 0
        }

        new Notification(
          payload.notification.title || 'sin titulo',
          {
            body: payload.notification.body || 'epc-tracker',
            icon: payload.notification.icon || 'https://epcv2-global-assets.s3-eu-west-1.amazonaws.com/public/logotriangulo.png'
          }
        );
      });

    },

    async connectToPusher() {
      // Obtenemos al usuario
      const user = store.getters["auth/user"];
      var self = this;

      // Si existe el usuario, abrimos el canal
      if (user && window.Echo == undefined) {

        // LARAVEL ECHO & PUSHER
        let connection = window.Echo = new Echo({
          broadcaster: "pusher",
          key: process.env.MIX_PUSHER_APP_KEY,
          cluster: process.env.MIX_PUSHER_APP_CLUSTER,
          encrypted: true,
          authEndpoint: "/user/authEcho",
          disableStats: true,
          auth: {
            headers: {
              Authorization: "Bearer " + store.getters["auth/token"]
            }
          }
        });

        var channel = window.Echo.private("App.User." + user.id);

        // Incluimos el nombre del cliente por si utilizamos el mismo canal de pusher para varios entornos
        channel.on("notify", function(e) {

          // mis propias notifs, siempre silent sea lo q sea
          if( e.executor_user_id && e.executor_user_id == user.id ){
            e.sound === 'false'
          }

          let refreshNotifications = e.sound === 'false' ? false : true;

          switch(e.type) {
            case "MessageChatPusher":
              EventBus.$emit("refreshChatNotifications", true);
              refreshNotifications = false;
              break;
            case "refreshProgress":
              EventBus.$emit("refreshProgress", e.progress);
              refreshNotifications = false;
              break;
            case "refreshStatus":
              EventBus.$emit("refreshStatus", e.status);
              refreshNotifications = false;
              break;
            case 'IssueDeleted':
              refreshNotifications = false;
              break;
            case 'IssueAssigned':
              EventBus.$emit("refreshIssuesNotifications", true);
              break;
            case 'ActivityDeleted':
              refreshNotifications = false;
            break;
            case 'ActivityAssigned':
              EventBus.$emit("refreshActivitiesNotifications", true);
              break;
            case 'SubactivityDeleted':
              refreshNotifications = false;
              break;
            case 'SubactivityAssigned':
              EventBus.$emit("refreshSubactivitiesNotifications", true);
              break;
            case 'SubactivityDocumentDeleted':
            case 'ActivityDocumentDeleted':
              EventBus.$emit("refreshActivityDocumentsNotifications", false);
              refreshNotifications = false;
              break;
            case 'SubactivityDocumentUploaded':
            case 'ActivityDocumentUploaded':
              EventBus.$emit("refreshActivityDocumentsNotifications", false);
              break;
            case 'IssueDocumentDeleted':
              refreshNotifications = false;
              break;
            case 'IssueDocumentUploaded':
              EventBus.$emit("refreshIssueDocumentsNotifications", true);
              break;
            case 'ActivityDocumentUpdated':
            case 'SubactivityDocumentUpdated':
              EventBus.$emit("refreshActivityDocumentsNotifications", true);
              EventBus.$emit("refreshAssignmentChangedNotifications", e);
              break;

            case 'FolderUpdated':
              refreshNotifications = false;
              EventBus.$emit("refreshFolderDocuments", e);
              EventBus.$emit("refreshActivityDocumentsNotifications", e);
              break;


            // FLUJO DE VALIDACION

            case 'ValidationFlowAssigned':
              refreshNotifications = true;
              EventBus.$emit("refreshValidations", e);
              break;

            case 'ParticipantsValidationFlowNotification':
              refreshNotifications = false;
              EventBus.$emit("refreshValidationTasks", e);
              EventBus.$emit("refreshValidations", e);
              break;

            case 'RejectedValidationFlowTaskNotification':
            case 'NextValidationFlowTaskNotification':
            case 'ValidationFlowPendingAnswer':
              // refreshNotifications = true;. No forzar, hacer lo que diga e.sound arriba, para no notificar al usuario que hace la accion
              break;

            case 'ValidationDeleted':
              refreshNotifications = false;
              EventBus.$emit("refreshValidations", e);
              break;

            case 'ValidationCompleted':
              refreshNotifications = false;
              EventBus.$emit("refreshValidations", e);
              break;

            case 'ValidationRejected':
              refreshNotifications = false;
              EventBus.$emit("refreshValidations", e);
              break;

            // ----------------------------------------------


            case 'IssueDocumentUpdated':
              refreshNotifications = false;
              EventBus.$emit("refreshIssueDocumentsNotifications", true);
              break;
            case 'ValidationDocumentUpdated':
              refreshNotifications = false;
              EventBus.$emit("refreshValidationDocumentsNotifications", true);
              break;
            case 'ProjectDocumentUpdated':
            case 'ProjectDocumentDeleted':
              refreshNotifications = false;
              EventBus.$emit("refreshProjectDocumentsNotifications", e);
              break;
            case "readNotifications":
              refreshNotifications = false;
              break;
            case 'SubActivityCompleted':
              refreshNotifications = false;
              break;
            case 'ActivityCompleted':
              refreshNotifications = false;
              break;
            case 'ActivityProgressChange':
              refreshNotifications = false;
              break;
            case 'AssignmentChanged':
            case 'MeasurementProgressRejected':
              EventBus.$emit("refreshAssignmentChangedNotifications", e);
              refreshNotifications = false;
              break;
            case 'AssignmentDeleted':
              EventBus.$emit("refreshAssignmentDeletedNotifications", e);
              refreshNotifications = false;
              break;
            case 'AssignmentCreated':
              EventBus.$emit("refreshAssignmentCreatedNotifications", e);
              refreshNotifications = false;
              break;
            case 'KanbanRefresh':
              EventBus.$emit("kanbanRefresh", e);
              refreshNotifications = false;
              break;
            case 'ActivityCommented':
            case 'SubactivityCommented':
              e.entity = 'activity';
              EventBus.$emit("refreshComments", e);
              break;
            case 'ActivityCommentUpdated':
            case 'SubactivityCommentUpdated':
            case 'ActivityCommentDeleted':
              refreshNotifications = false;
              e.entity = 'activity';
              EventBus.$emit("refreshComments", e);
              break;
            case 'IssueCommented':
              e.entity = 'issue';
              EventBus.$emit("refreshComments", e);
              break;
            case 'IssueCommentUpdated':
            case 'IssueCommentDeleted':
              refreshNotifications = false;
              e.entity = 'issue';
              EventBus.$emit("refreshComments", e);
              break;
            case 'ValidationCommented':
              e.entity = 'validation';
              EventBus.$emit("refreshComments", e);
              break;
            case 'ValidationCommentUpdated':
            case 'ValidationCommentDeleted':
              refreshNotifications = false;
              e.entity = 'validation';
              EventBus.$emit("refreshComments", e);
              break;
            case 'zipReady':
              // Pusher notifs are private per user. Conditional just in case, due to sensitive information
              if( e.executor_user_id && e.executor_user_id == user.id ){
                window.open(e.url);
                self.$notify.success('downloaded_folder');
              }

              break;
            case 'importEntities':
              if( e.executor_user_id && e.executor_user_id == user.id ){
                let importEntities = self.importEntities || {}
                if (!importEntities[e.entity_type]) {
                  importEntities[e.entity_type] = {}
                }
                if (e.status == 'success') {
                  // subimos excel
                  let params = {...e.params, data: importEntities[e.entity_type][`${e.project_id}`].data}
                  axios.post(`/api/v2/import/${e.entity_type}/optimized/upload`, params)
                    .catch(err => {
                      // console.log(err);
                    })
                    .finally(() => {
                      // notificamos y reseteamos storage
                      self.$notify.success('success_import');
                      importEntities[e.entity_type][`${e.project_id}`] = {}
                      self.$store.dispatch('app/setImportEntities', {importEntities})
                    });

                } else {
                  let newImportEntities = {
                    type: e.import_type, 
                    lastImportedIndex: e.last_imported_index, 
                    status: importEntities[e.entity_type][`${e.project_id}`].status,
                    data: importEntities[e.entity_type][`${e.project_id}`].data
                  }
                  if (e.status == 'error') {
                    self.$notify.error('error_import');
                    newImportEntities.status = 'error'
                    newImportEntities.errors = e.data
                  } else  {
                    self.$notify.success('progress_import');
                  }
                  importEntities[e.entity_type][`${e.project_id}`] = newImportEntities
                  self.$store.dispatch('app/setImportEntities', {importEntities})
                }
              }

              break;

            case 'BulkAction':
              // Bulk Action notifs are private per user
              if( e.executor_user_id && e.executor_user_id == user.id ){
                let dataBulk = JSON.parse(e.data)
                self.$notify.success(dataBulk.message);
              }
              break;

            case 'Processlog':
              // Bulk Action notifs are private per user
              if ( e.executor_user_id && e.executor_user_id == user.id ) {
                EventBus.$emit("processQueueLog", e);
              }
              break;
          }

          // Refrescamos notificaciones
          self.notifications = [];
          self.getNotifications(refreshNotifications, 1);

          if (refreshNotifications) {
            EventBus.$emit("refreshNotifications", true);
          }

        });
      }
    },
    async getNotifications(launchToast, page) {
      // Referenciamos
      var self = this;

      // Marcamos
      this.onlyUnread = false;
      this.page = page;

      // Obtenemos las notificaciones sin leer del usuario
      const { data } = await axios.get(
        "/api/v2/user/notifications/web?page=" + page
      );

      // Paginas totales
      this.last = data.last;

      //console.log(data);
      if (data && data.success) {
        // Hacemos comprobaciones de la paginacion
        if (this.page > 1 && this.page <= this.last) {
          data.data.forEach(function(element) {
            self.notifications.push(element);
          });

          this.isLoadingTable = false;
          this.page = this.page + 1;
        } else {
          self.notifications = data.data;
          this.isLoadingTable = false;
          this.page = this.page + 1;
        }

        // Variables para mostrar total de notificaciones independiente de la paginacion
        this.total = data.unread;
        this.total > 0 ? (this.isPublic = true) : (this.isPublic = false);
      }

      if (launchToast) {
        // TODO. quitado el sound por problemas de repiques. Activar cuando sea resuelto
        // self.playSound(self.asset('public/sounds/notification.mp3'));
        this.$notify.info("new_notification");
      }
    },

    async goToNotification(notification) {

      // Preparamos el array para enviarlos
      var notifications = { id: notification };

      // Marcamos como leída la notificación
      const { data } = await axios.post("/api/v2/notification/read", {
        notifications: notifications
      });

      if (data && data.success) {

        // Parseamos la respuesta
        var obj = JSON.parse(data.data.data);

        let notificationProjectId = obj.activity_project_id || obj.flow_project_id || obj.project_id

        let dataProjectNotification = await this.getDataProject(notificationProjectId); // retorna un objeto que contiene project y company
        let currentProjectId = this.project ? this.project.id : null; // identificador del projecto actual o null si no se selecciono aún

        // Verificamos si la notificación pertenece a un proyecto diferente al actual
        if( dataProjectNotification.project && dataProjectNotification.project.id != currentProjectId ) {
          let notificationCompany = dataProjectNotification.company
          // verificamos si la company es diferente a la actual si lo es seteamos en el storage la company antes de setear el proyecto
          if( notificationCompany.id != this.company.id ) {

            await this.$store.dispatch("app/setCompany", { company: notificationCompany });
            // await this.$store.dispatch("app/setGroup", { group: null });
          }

          // por defecto seteamos siempre el project si es diferente
          let notificationProject = dataProjectNotification.project
          await this.$store.dispatch("app/setProject", { project: notificationProject });
          let tags = dataProjectNotification.project.tags
          await this.$store.dispatch("app/setTags", { tags });
        }

        if (obj.type == "ProjectAssigned") {
          // // const { data } = await axios.get("/api/v2/user/projects", {
          // //   'params' : { companyId: this.company.id }
          // // });

          // // let project = data.projects.find(proj => proj.id == obj.activity_project_id)
          // await this.$store.dispatch("app/setProject", { project });
          // let tags = project.tags
          // await this.$store.dispatch("app/setTags", { tags });

          // Redirigimos a la ruta
          this.$router.push(
            "/project"
          );
        } else if (
          obj.type == "SubactivityAssigned" ||
          obj.type == "SubactivityCommented" ||
          obj.type == "SubactivityDocumentUploaded" ||
          obj.type == "SubactivityDocumentMention" ||
          obj.type == "SubactivityCommentMention" ||
          obj.type == "SubactivityCompleted"
        ) {
          // Redirigimos a la ruta
          this.$router.push({
            name: "subactivity.home",
            params: {
              activity_id: obj.activity_parent_id,
              subactivity_id: obj.activity_id
            }
          });
        } else if (
          obj.type == "IssueCommented" ||
          obj.type == "IssueCommentMention" ||
          obj.type == "IssueDocumentUploaded" ||
          obj.type == "IssueDocumentMention" ||
          obj.type == "IssueAssigned"
        ) {
          // Redirigimos a la ruta adecuada según si es incidencia de subactividad o no
          if (obj.activity_subactivity) {
            this.$router.push({ name: "issue.home-sub", params: { subactivity_id: obj.activity_id, activity_id: obj.activity_parent_id, issue_id: obj.issue_id } });
          } else {
            this.$router.push({ name: "issue.home", params: { activity_id: obj.activity_id, issue_id: obj.issue_id } });
          }

        } else if (
          obj.type == "ActivityAssigned" ||
          obj.type == "ActivityCommented" ||
          obj.type == "ActivityDocumentUploaded" ||
          obj.type == "ActivityDocumentMention" ||
          obj.type == "ActivityCommentMention" ||
          obj.type == "ActivityCompleted"
        ) {
          // Redirigimos a la ruta
          this.$router.push({
            name: "activity.home",
            params: { activity_id: obj.activity_id }
          });
        } else if (
          obj.type == "ValidationDocumentMention" ||
          obj.type == "NextValidationFlowTaskNotification" ||
          obj.type == "RejectedValidationFlowTaskNotification" ||
          obj.type == "ValidationFlowAssigned" ||
          obj.type == "ValidationCommented" ||
          obj.type == "ValidationCommentMention"
        ) {
          // Redirigimos a la ruta
          this.$router.push({
            name: "validation.home",
            params: { validation_id: obj.flow_id }
          });
        } else if (obj.type == "MeasurementProgressRejected") {
          // Redirigimos a la ruta
          this.$router.push({
            name: "project.measurements"
          });
        }



        // Obtenemos las notificaciones de nuevo.
        this.getNotifications(false, this.page);
      }
    },

    async getDataProject(id) {
      let companies = this.companies

      let project = null;
      let company = null;

      for (let index = 0; index < companies.length; index++) {
        company = companies[index]
        if( company.projects ) {
          project = company.projects.find(p => p.id == id);
          if( project )
            break
        }
      }

      return { project: project, company: company };
    }
  },

  created() {
    this.getNotifications(false, this.page), this.connectToPusher();
    this.connectToFirebase();
  }
};
</script>

<style scoped>
.line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.line-clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.line-clamp-none {
  overflow: visible;
  display: block;
  -webkit-box-orient: horizontal;
  -webkit-line-clamp: none;
}
</style>

