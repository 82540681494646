<template>
  <form @submit.prevent="send" @keydown="form.onKeydown($event)">
    <l-form-input
      :form="form"
      field="email"
      placeholder="email"
      inputType="email"
      size="is-medium"
      icon="envelope"
    />
    <h1 v-if="environment == 'staging'" class="text-center text-red font-bold">No disponible en entorno de pruebas</h1>
    <b-field class="mt-5">
      <v-button
        :disabled="environment == 'staging'"
        medium
        full
        :loading="form.busy"
        native-type="submit"
      >{{ $t('send_password_reset_link') }}</v-button>
    </b-field>
  </form>
</template>

<script>
import Form from "vform";

export default {
  middleware: "guest",
  layout: "login",
  title: "reset_password",

  data: () => ({
    environment: window.config.env,
    form: new Form({
      email: ""
    })
  }),

  methods: {
    async send() {
      const { data } = await this.form.post(
        "/api/v2/password/recovery/" + this.form.email
      );

      if (data && data.msg) {
        this.$toast.open({
          message: data.msg,
          type: data.success ? "is-success" : "is-danger"
        });
      }

      // Form reset
      this.form.reset();

      // Redirect to login.
      this.$router.push({ name: "login" });
    }
  }
};
</script>
