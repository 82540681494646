<template>
    <div>
        <h4>
            <strong>
                TOTAL:
            </strong>
            {{ formatNumber(total_indirectCosts) }}
        </h4>
        <div class="panel">
            <b-table :data="indirectCosts" :loading="isLoadingTable" paginated backend-pagination :per-page="perPage"
                :total="total" @page-change="onPageChange" backend-sorting :default-sort="orderField"
                :default-sort-direction="orderDir" @sort="onSort" class="w-full" striped bordered icon-pack="fas"
                detailed detail-key="id" :show-detail-icon="showDetailIcon" scrollable>
                <template>
                    <b-table-column field="code" :label="$t('code')" sortable v-slot="props">
                        {{ props.row.code }}
                    </b-table-column>
                    <b-table-column field="name" :label="$t('indirect_cost_name')" sortable v-slot="props">
                        {{ props.row.name }}
                    </b-table-column>
                    <b-table-column field="price" :label="$t('amount')" sortable v-slot="props">
                        {{ formatNumber(props.row.price) }}
                    </b-table-column>
                    <b-table-column :label="$t('actions')" field="actions" centered width="150" v-slot="props">
                        <div class="w-full h-full flex items-center justify-around">
                            <b-tooltip :label="$t('edit_indirect_cost')" position="is-left">
                                <div @click="editButtonClicked(props.row)" class="cursor-pointer flex text-blue">
                                    <b-icon pack="fas" icon="pencil-alt" />
                                </div>
                            </b-tooltip>
                            <b-tooltip :label="$t('edit_indirect_cost_detail')" position="is-left">
                                <div @click="editDetalilButtonClicked(props.row)" class="cursor-pointer flex text-blue">
                                    <b-icon pack="fas" icon="file-pen" />
                                </div>
                            </b-tooltip>
                            <b-tooltip :label="$t('delete_indirect_cost')" position="is-left" type="is-danger">
                                <div @click="removeIndirectCost(props.row)" class="cursor-pointer flex text-red">
                                    <b-icon pack="fas" icon="trash-alt" />
                                </div>
                            </b-tooltip>
                        </div>
                    </b-table-column>
                </template>
                <template #detail="data">
                    <div v-if="data.row.indirect_cost_details.length > 0">
                        <b-table :data="data.row.indirect_cost_details" :loading="isLoadingTable" backend-sorting
                            class="w-full" striped bordered icon-pack="fas" scrollable detailed detail-key="id"
                            :show-detail-icon="showDetailIcon">
                            <template>
                                <b-table-column field="description" :label="$t('name')" v-slot="data">
                                    {{ data.row.description }}
                                </b-table-column>
                                <b-table-column field="code" :label="$t('code')" v-slot="data">
                                    {{ data.row.code }}
                                </b-table-column>
                                <b-table-column field="price" :label="$t('price')" v-slot="data">
                                    {{ formatNumber(data.row.price) }}
                                </b-table-column>
                                <b-table-column field="quantity" :label="$t('quantity')" v-slot="data">
                                    {{ data.row.quantity }}
                                </b-table-column>
                                <b-table-column field="total" :label="$t('amount')" v-slot="data">
                                    {{ formatNumber(data.row.total) }}
                                </b-table-column>
                                <b-table-column field="actions" :label="$t('actions')" v-slot="data" centered>
                                    <div class="w-full h-full flex items-center justify-center">
                                        <b-tooltip :label="$t('add_invoice')" position="is-left">
                                            <div @click="openInvoiceIndirectCost(data.row)"
                                                class="cursor-pointer flex text-dark">
                                                <b-icon pack="fas" icon="circle-plus" />
                                            </div>
                                        </b-tooltip>
                                    </div>
                                </b-table-column>
                            </template>
                            <template #detail="invoice">
                                <div v-if="invoice.row.invoice_cost_indirects.length > 0">
                                    <div class="flex justify-between">
                                        <h1 class="text-xl font-bold text-gray-800 mb-4">{{ $t('billing') }}</h1>
                                        <h1 class="text-xl font-bold text-gray-800 mb-4">
                                            <!-- {{ $t('total') }}:
                                            {{ formatNumber(data.row.indirect_cost_details[0].total_invoice) }} -->
                                        </h1>
                                    </div>
                                    <table class="table">
                                        <thead class="bg-gray-200">
                                            <tr>
                                                <th>{{ $t('quantity') }}</th>
                                                <th>{{ $t('Import de coste real a Origen') }}</th>
                                                <th>{{ $t('actions') }}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="bg-gray-200"
                                                v-for="(assigment, index) in invoice.row.invoice_cost_indirects"
                                                :key="index">
                                                <td class="text-center"> {{ formatNumber(assigment.assignment) }}</td>
                                                <td class="text-center">{{ formatNumber(assigment.price) }}</td>
                                                <td class="text-center">
                                                    <b-tooltip :label="$t('edit_billing')" position="is-left">
                                                        <div @click="editButtonInvoice(data.row, assigment)"
                                                            class="cursor-pointer flex justify-center text-dark mx-4">
                                                            <b-icon pack="fas" icon="pencil-alt" />
                                                        </div>
                                                    </b-tooltip>
                                                    <b-tooltip :label="$t('delete_billing')" position="is-left"
                                                        type="is-danger">
                                                        <div @click="removeInvoice(assigment)"
                                                            class="cursor-pointer flex justify-center text-dark">
                                                            <b-icon pack="fas" icon="trash-alt" />
                                                        </div>
                                                    </b-tooltip>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div v-else>
                                    <p class="text-gray-800">{{ $t('no_more_results') }}</p>
                                </div>
                            </template>
                        </b-table>
                    </div>
                    <div v-else>
                        <p class="text-gray-800">{{ $t('no_more_results') }}</p>
                    </div>
                </template>


                <template #empty>
                    <div class="has-text-centered">{{ $t('no_results') }}</div>
                </template>
            </b-table>
        </div>
        <!-- InvoiceCostIndirect Modal -->
        <InvoiceCostIndirect :projectId="projectId" ref="invoice_indirect_cost_modal" @reload-list="handleReload" />
    </div>
</template>

<script>
import axios from 'axios'
import InvoiceCostIndirect from './invoice-cost-indirect-modal.vue';

export default {
    components: {
        InvoiceCostIndirect
    },
    props: {
        projectId: { type: Number, require: true },
    },
    computed: {
        filters() {
            let filters = {
                'order_field': this.orderField,
                'order_dir': this.orderDir,
                'perPage': this.perPage,
                'page': this.page,
                'project_id': this.projectId
            }
            return filters
        },
    },
    created() {
        this.getIndirectCost()
    },
    data: function () {
        return {
            isLoadingTable: true,
            perPage: 25,
            orderField: 'code',
            orderDir: 'asc',
            page: 1,
            last: 1,
            total: 0,
            indirectCosts: [],
            total_indirectCosts: null,
            showDetailIcon: true,
            isInvoiceCostIndirectModalActive: false
        }
    },
    methods: {
        async getIndirectCost(filters = null) {
            this.isLoadingTable = true;
            this.products = [];
            const params = filters ? filters : this.filters;
            try {
                const { data } = await axios.get(`/api/v2/indirect-cost`, { params });

                if (data && data.success) {
                    this.indirectCosts = data.indirect_costs;
                    this.last = data.last_page;
                    this.total = data.total;
                    this.total_indirectCosts = data.total_price;
                }
            } catch (error) {
                throw ('Error fetching indirectCost:', error);
            } finally {
                this.isLoadingTable = false;
            }
        },

        onSort(field, order) {
            this.orderField = field;
            this.orderDir = order;
            this.getIndirectCost();
        },

        onPageChange(page) {
            this.page = page;
            this.getIndirectCost();
        },

        reload(arg = {}) {
            let filters = { ...arg, ...this.filters }
            this.getIndirectCost(filters);
        },
        async editButtonClicked(entity) {
            this.$emit('epc-edit', entity)
        },
        editDetalilButtonClicked(indirectCost) {
            this.$router.push({
                name: 'project.admin.cost-management.indirect-cost',
                params: {
                    projectId: this.projectId,
                    indirectCost: indirectCost
                }
            })
        },

        async removeIndirectCost(indirectCost) {
            try {
                // Confirmar la acción de eliminación
                this.$dialog.confirm({
                    title: this.$t('delete'),
                    message: this.$t('delete_confirm_text', [indirectCost.name]),
                    confirmText: this.$t('delete'),
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: async () => {
                        this.processingData = true;
                        // Enviar solicitud DELETE al backend
                        await axios.delete(`/api/v2/indirect-cost/${indirectCost.id}`);

                        this.$buefy.toast.open({
                            message: this.$t("removed_successfully"),
                            type: 'is-success'
                        });

                        // Recargar la lista de recursos o datos
                        this.reload();
                    },
                    onCancel: () => {
                        this.processingData = false;
                    }
                });
            } catch (error) {
                this.$buefy.toast.open({
                    message: this.$t("remove_error") + (error.response.data.message || ''),
                    type: 'is-danger'
                });
            } finally {
                this.processingData = false;
            }
        },
        async removeInvoice(invoiceIndirectCost) {
            try {
                // Confirmar la acción de eliminación
                this.$dialog.confirm({
                    title: this.$t('delete'),
                    message: this.$t('delete_billing_confirm_text', [invoiceIndirectCost.id]),
                    confirmText: this.$t('delete'),
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: async () => {
                        this.processingData = true;
                        // Enviar solicitud DELETE al backend
                        await axios.delete(`/api/v2/invoice/indirect-cost/${invoiceIndirectCost.id}`);

                        this.$buefy.toast.open({
                            message: this.$t("removed_successfully"),
                            type: 'is-success'
                        });

                        // Recargar la lista de recursos o datos
                        this.reload();
                    },
                    onCancel: () => {
                        this.processingData = false;
                    }
                });
            } catch (error) {
                this.$buefy.toast.open({
                    message: this.$t("remove_error") + (error.response.data.message || ''),
                    type: 'is-danger'
                });
            } finally {
                this.processingData = false;
            }
        },
        formatNumber(value) {
            const number = parseFloat(value);
            if (isNaN(number)) return '0,00';
            let [integerPart, decimalPart] = number.toFixed(2).split('.');
            integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            return `${integerPart},${decimalPart}`;
        },
        openInvoiceIndirectCost(indirectCost) {
            this.$refs.invoice_indirect_cost_modal.show(indirectCost);
        },
        async editButtonInvoice(indirectCost, invoiceIndirectCost) {
            this.$refs.invoice_indirect_cost_modal.show(indirectCost, invoiceIndirectCost);
        },

        handleReload() {
            this.reload();
        }


    },

}
</script>

<style lang="scss" scoped>
table {
    border-collapse: none;
}

table td,
.table th {
    border: 1px solid #E2E8F0;
    border-width: 1px;
    padding: 0.5em 0.75em;
    vertical-align: top;
    text-align: center;
}

.table th {
    background-color: #4A5568;
    color: #FFFFFF;
}
</style>