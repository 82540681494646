<template>
<div class="w-full flex flex-col lg:flex-row">
    <div class="w-full lg:hidden containt-tabs-menu">
      <!-- <ul class="w-full flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200">
          <li class="mr-2">
              <a href="#"
                aria-current="page" class="inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg active">Profile</a>
          </li>
          <li class="mr-2">
              <a href="#"
                class="inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50">Dashboard</a>
          </li>
          <li class="mr-2">
              <a href="#" class="inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50">Settings</a>
          </li>
          <li class="mr-2">
              <a href="#" class="inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50">Contacts</a>
          </li>
          <li>
              <a class="inline-block p-4 text-gray-400 rounded-t-lg cursor-not-allowed">Disabled</a>
          </li>
      </ul> -->
      <b-tabs ref="refTabsMenu"
        type="is-toggle-rounded"
        v-model="tabSelectedMenu"
        expanded
        class="sticky top-0 mb-3"
        @input="_HandleChangeTab">

        <b-tab-item label="Comentarios"
          value="tab_menu_comments"
          icon="comment-alt"
          icon-pack="fas" class="hidden"></b-tab-item>

        <b-tab-item
          label="Dashboard"
          value="tab_menu_dashboard"
          icon="chart-line"
          icon-pack="fas"></b-tab-item>

      </b-tabs>
    </div>

    <div class="w-full flex flex-row">
      <!-- Columna izquierda -->
      <div class="w-full lg:w-1/2 lg:mr-2" v-show="showPanel == 'left' || showPanel == 'both'">
        <div v-if="project.can_admin || project.can_participate || project.can_view" class="w-full column-scroll">
          <!-- Actividad -->
          <project-notifications ref="project_notifications"/>
        </div>
        <div v-else class="w-full column-scroll">
          <div class="panel">
            <span>{{ $t('empty_project_notifications') }}</span>
          </div>
        </div>

      </div>

      <!-- Columna derecha -->
      <div class="w-full lg:w-1/2 lg:ml-2" v-show="showPanel == 'right' || showPanel == 'both'">
        <div class="w-full column-scroll">

          <div v-if="showBtnDashboard"
            class="panel flex flex-wrap justify-center space-x-2 space-y-2">
              <button v-for="(btn, i) in GetBtnDashboard()" :key="'btn_dash' + i"
                class="button is-primary btn-blue mt-2 ml-2 is-size-7"
                @click="$router.push({ name: 'project.dashboard.' + btn.suffix_route})">
                  <!-- <b-icon pack="fas" icon="chart-line" size="is-small" class="mr-2" /> -->
                  {{ $t(btn.text) }}
              </button>
          </div>

          <!-- Dashboard Canal (Plan Sanea) (por proyecto, y antes de check if isLoteCanal porque hay companies en ambos planes -->
          <!-- <div class="panel grid place-items-center" v-else-if="isPlanSanea">
            <v-button icon="chart-line" class="" @click="$router.push({ name: 'project.dashboard.sanea'})">Dashboard</v-button>
          </div> -->

          <!-- Dashboard Canal (Plan Red) -->
          <!-- <div class="panel grid place-items-center" v-else-if="company && company.isLoteCanal">
            <v-button icon="chart-line" class="" @click="$router.push({ name: 'project.dashboard'})">Dashboard</v-button>
          </div> -->

          <!-- PANEL DE FILTROS -->
          <div class="panel">
            <project-notifications-filter
              :project-id="project ? project.id : 0"
              @reload-notifications="reloadNotifications"
            />
          </div>

          <div v-if="project && project.can_admin" class="panel">
              <project-assignments-preview />
          </div>

          <div class="panel">
            <project-activities-preview />
          </div>

          <!-- <div v-if="project && project.can_admin" class="panel">
              <project-charts
                :project="project"
                :title="$t('progress_label')"
                chartKey="progress"
                :chartType="'line'"
                :isLoading="true" />
          </div>
          <div v-if="project && project.can_admin" class="panel">
              <project-charts
                :project="project"
                :title="$t('price')"
                chartKey="costs"
                :chartType="'column'"
                :isLoading="true" />
          </div> -->
          <div v-if="project && project.can_admin && project.id != 580" class="panel"><!-- cuadro ocultado temporalmente al projecto 580 por necesidad de Jose -->
              <project-economic-preview />
          </div>
        </div>
      </div>
    </div>
</div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import { mapGetters } from 'vuex'
import projectNotifications from '·/components/project-notifications'
import activityTimeStatusCounters from '·/components/activity-time-status-counters'
import projectNotificationsFilter from '·/components/project-notifications-filter'
import projectActivitiesPreview from '·/components/project-activities-preview'
import projectEconomicPreview from '·/components/project-economic-preview'
import projectAssignmentsPreview from '·/components/project-assignments-preview'
import projectCharts from '·/components/project-charts'
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
import VueChartkick from 'vue-chartkick'
import Chart from 'chart.js'
import moment from 'moment'

Vue.use(VueChartkick, {adapter: Chart})
Vue.use(moment)

export default {
  middleware: 'auth',
  title: 'project_home_page_title',
  subtitle: 'project_home_page_subtitle',
  menuOption: '2-1',

  components: {
    projectNotifications,
    activityTimeStatusCounters,
    ScaleLoader,
    projectNotificationsFilter,
    projectActivitiesPreview,
    projectEconomicPreview,
    projectAssignmentsPreview,
    projectCharts
  },

  data: () => ({
    activitiesList: null,
    okCounter: 0,
    warningCounter: 0,
    dangerCounter: 0,
    usersWorkLoad: null,
    isLoadingTable: true,
    status_assigned: 0,
    status_running: 0,
    status_paused: 0,
    status_issue: 0,
    status_done: 0,
    status_reopened: 0,
    status_closed: 0,

    showPanelRight: true,
    showPanelLeft: true,
    // showPanel: 'both',

    tabSelectedMenu: -1 // tab seleccionado por defecto

  }),

  computed: {
    ...mapGetters({
      user: 'auth/user',
      project: 'app/project',
      company: 'app/company',
      mobile: 'device/mobile',
      visualConfig: 'app/visualConfig', // project configs
    }),

    showPanel: function() {
      if( this.mobile.on_mobile ) {

        if( this.showPanelLeft && this.showPanelRight ) {
          this.tabSelectedMenu = 1
          this.showPanelLeft = false
        }

        return this.showPanelRight ? 'right' : 'left'
      }

      this.showPanelRight = true
      this.showPanelLeft = true
      return 'both'
    },

    isPlanSanea: function() {
      return this.visualConfig?.is_plan_sanea
    },

    showBtnDashboard: function() {
      return this.project.configs && this.project.configs.dashboards && this.GetBtnDashboard().length > 0
    }
  },

  watch: {

  },

  mounted() {

  if (this.project == null) {

      this.$router.push('/projects');

    } else {

      this.getActivitiesList()
      //this.getWorkload()
      let pageInfo = {
        title: this.project.name,
        subtitle: this.$t('project_home_page_subtitle'),
        menuOption: '2-1',
      }

      this.$store.dispatch('app/updatePageInfo', {info: pageInfo})
    }
  },

  methods: {
    async getActivitiesList() {
      const { data } = await axios.get('/api/v2/activities?p=' + this.project.id+'&pg=1')
      if (data && data.success) {
        this.activitiesList = data.activities
        this.calculateActivitiesCounters()
      }
    },
    async getWorkload() {
      var self = this
      await axios.get('/api/v2/project/' + this.project.id + '/workload')
        .then(function (response) {
          if (response.data && response.data.success) {
            self.usersWorkLoad = response.data.result
          }
        })
        .catch(error => {
          self.$notify.error('error_workload')
        });

      this.isLoadingTable = false
    },
    calculateActivitiesCounters() {

      this.activitiesList.forEach((activity) => {

        let diff = this.diffDate(activity.current_end_date)
        if (diff > 3) {
          this.okCounter++
        } else if (diff <=3 && diff > 0) {
          this.warningCounter++
        } else {
          this.dangerCounter++
        }

        switch(activity.status){
          case 0:
            this.status_assigned++
          break;
          case 1:
            this.status_running++
          break;
          case 2:
            this.status_paused++
          break;
          case 3:
            this.status_issue++
          break;
          case 4:
            this.status_done++
          break;
          case 5:
            this.status_reopened++
          break;
          case 6:
            this.status_closed++
          break;
        }

      })
    },

    diffDate(date) {
      let end = moment(date, "DD/MM/YYYY")
      let now = moment()
      let duration = moment.duration(end.diff(now))
      return duration.asDays()
    },

    reloadNotifications(filters) {
      this.$refs.project_notifications.updateFilters(filters)
    },

    _HandleChangeTab(value) {
      // value contiene el index del tab pulsado siendo
      // 0= el primero tab que corresponde a al menu comments panel comentarios
      // 1= el segundo tab que corresponde al menu dasboard panel derecho

      if (this.mobile.on_mobile) {
        this.showPanelRight = value === 1
        this.showPanelLeft = value === 0
        console.log('change tabvalue: ', value)
      }
      // console.log('change tabSelectedMenu: ', this.tabSelectedMenu)
      // console.log( this.$refs.refTabsMenu.items[this.tabSelectedMenu].label )
    },

    GetBtnDashboard() {
        return this.project && this.project.configs && this.project.configs.dashboards
          ? this.project.configs.dashboards.filter(btn => btn.visible)
          : []
    }
  },

};
</script>

<style scoped>
.containt-tabs-menu::v-deep .b-tabs section {
  display: none;
}

.column-scroll {
  /* width: 50%; */
  height: calc(100vh - 150px);
  overflow-x: hidden;
  overflow-y: auto;
}
/* .column-scroll:not(:last-child) {
  margin-right: 0.5rem;
} */
/* .column-scroll:last-child {
  margin-left: 0.5rem;
} */
</style>
